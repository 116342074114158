// Insert your styling here.
//color


@dark-orange: #ea5b0c;
@orange: #f39200;
@light-orange: #f9b233;
@yellow: #f8ea1b;
@green: #96c11f;
@white-purple: #e9e8f0;
@light-purple: #7c6fb0;
@purple: #4d358c;
@dark-purple: #382362;
@black-purple: #2c174a;
@dark-blue: #263238;
@background: #fafbfc;
@background2: #eeeff0;
@CR: 'Crete Round';
@OS: 'Open Sans';
@RS: 'Roboto Slab';


body {font-family: @OS; overflow-x: hidden;
  .navbar-is-fixed-top {padding-top: 89px !important;}
}
.flexslider {background: transparent; border-radius: 0; border: none; margin: 0;}
.not-front { background-color: #f2f2f2;
  &:before {position: absolute; content: ""; top: 85px; left: 25vw; width: 0; height: 0; border-top: 900px solid #fff; border-left: 75vw solid transparent;}
  h1 {color: @purple; font-family: @RS; font-weight: bold; text-transform: uppercase; font-size: 38px; border: none; line-height: 1.25;}
}

#navbar {
  .navbar-header {position: relative; z-index: 9;}
  #block-block-2 { float: right;
    span {margin-top:32px; height: 34px; width: 34px; position: relative; float: left; z-index: 9999; background: url(../images/sprite.png) no-repeat; background-position: -7px -2px; transition-duration: 300ms;}
    &.active {
      span {background-position: -59px -2px; transition-duration: 300ms;}
    }
  }
  &.sticky {
    #block-block-2 {margin-top: 15px; transition-duration: 300ms;}
  }
}


//tb mega menu
body {
  .fix-menu {overflow: hidden;}
  #block-tb-megamenu-main-menu {display: none; position: absolute; top: 0; left: 0; width: 100%; height: 100vh; background-color: @white-purple; padding-top: 100px;
    &.fix-menu {display: block;}
    #tb-megamenu-main-menu { background: transparent;
      .nav-collapse {width: 1170px; margin: 0 auto; margin-top: 30px; position: relative;
        ul.tb-megamenu-nav {
          li.level-1 {position: inherit;
            a {background: transparent; color: @purple; text-transform: uppercase; font-family: @RS; font-size: 26px; border: none;
              &:hover {color: #fff; background: @black-purple;}
              span.caret {display: none;}
            }
            .tb-megamenu-submenu {left: 0; width: 100%; background: @black-purple; border: none; box-shadow: none;
              .view-views-in-menu {
                &.offer {
                  .field-row { padding: 0;
                    &:nth-child(4n+1) {clear: both;}
                    .field-content {
                      a {text-transform: inherit; color: @light-purple; padding: 22px 8px 22px 52px; text-decoration: none; font-size: 17px; position: relative; white-space: pre-wrap;
                        &:before {position: absolute; top: 24px; left: 11px; content: ""; width: 14px; height: 24px; background-image: url("../images/arrow-slider.png"); background-repeat: no-repeat; background-position: -4px -52px}
                        &:hover {color: #fff; background-color: @purple;}
                      }
                    }
                  }
                }
                &.blog {
                  .field-content { margin-left: 5px;
                    .row-1 { border: 1px solid @light-purple; margin-top: 40px; text-align: center; color: @white-purple; padding-bottom: 73px;
                      a {
                        &:hover {text-decoration: none;}
                        h3 {color: #fff; margin-top: 70px;}
                        span {font-size: 17px; color: @light-purple; width: 230px; max-width: 100%; margin: 0 auto 10px; padding: 5px 0; display: block; border-top: 1px solid @light-orange; border-bottom: 1px solid @light-orange;}
                        p {font-size: 14px; text-transform: none; font-family: @OS; color: @light-purple; margin-bottom: 0;}
                      }
                    }
                    .row-2 { margin-top: 25px; padding-left: 30px;
                      h5 {color: @white-purple; text-transform: uppercase; font-family: @RS; margin-top: 10px; border-bottom: 1px solid @purple; padding-bottom: 10px;}
                      .view-blog {
                        .blog-list {
                          &:first-child {padding-left: 0;}
                          &:last-child {padding-right: 0;}
                          .views-field {
                            .field-content { margin-left: 0; float: left; width: 100%; position: relative;
                              h4 { position: absolute; bottom: 0; left: 0; width: 100%; background: rgba(0, 0, 0, 0.7); margin-bottom: 0;
                                a {text-decoration: none; font-weight: bold; white-space: pre-wrap; font-size: 17px; line-height: 1.1em; color: #fff; padding: 18px 15px 25px;
                                  &:hover {background: transparent;}
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
                &.investor, &.farmer {
                  .field-content {float: left; width: 100%;  position: relative; overflow: hidden;  padding-bottom: 70px;  background: @purple;  margin-top: 50px;  margin-bottom: 20px;
                    &hover {background: @purple;}
                    img {position: absolute; top:0; transform:scaleX(-1)}
                    h3 {
                      a {color: #fff; text-transform: inherit; padding: 35px 0 20px; margin-bottom: 20px; border-bottom: 1px solid @light-purple;}
                    }
                    p {color: @light-purple;}
                  }
                }
              }
              .contact-in-menu { background:@purple; margin-top:35px; margin-bottom:30px; padding: 20px 0;
                .contact-img {
                  img { padding-left:60px; }
                }
                .contact-detail { color:#fff; padding-top: 30px;
                  p { margin: 0;}
                  h3 { font-family: @RS; margin-top:0; margin-bottom: 5px; }
                  h3.uppercase {padding: 0 0 20px; margin-bottom: 20px; border-bottom: 1px solid @light-purple; text-transform: uppercase; font-weight:bold; }
                  span { font-weight: bold;}
                  a{ color: #fff; font-size:14px; text-transform: lowercase; padding:0;}
                  div {padding: 0;
                    h5 { font-family: @RS;}
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}


//front page

.top-slider  {background-image: url(../images/slider-bigbg.jpg); background-repeat: no-repeat; background-position: top right; background-color: @black-purple; overflow: hidden;
  .view-front { position: relative;
    &:before {position: absolute; top: 0; right: 0; content: ""; width: 0; height: 0; border-bottom: 568px solid @black-purple; border-left: 568px solid transparent;}
    &:after {position: absolute; top: 0; left: calc(50% - 580px); content: ""; padding-left: 220px; width: 0; height: 0; border-top: 568px solid @dark-purple; border-right: 568px solid transparent; opacity: 0.7;}
    .flexslider { background: transparent; border: none; border-radius: 0; margin: 0; border-bottom: 2px solid @orange;
      &:after {position: absolute; top: 0; left: 0; content: ""; width: 568px; height: 568px; background-image: url(../images/slider-flower.png); background-repeat: no-repeat; z-index:1;}
      &:before {position: absolute; top: 0; left: calc(50% - 885px); padding-left: 300px; content: ""; width: 0; height: 0; border-top: 568px solid @black-purple; border-right: 568px solid transparent; z-index:1;}
      .slides { position: relative; z-index: 12;
        li {
          .views-field {
            .container { position: relative; height: 568px;
              .text-box {
                h3 {margin: 135px 0 20px; font-family: @CR; text-transform: uppercase; font-size: 36px;
                  a {color: @green;}
                }
                p {color: #fff; font-size: 18px; line-height: 1.3;}
                .more-btn {
                  a {padding: 0 18px; background: @green; color: #fff; line-height: 52px; float: left; clar: both; border-radius: 4px; margin-top: 33px;}
                }
              }
              img {width: initial; position: absolute; right: 0; bottom: 0;}
            }
          }
        }
      }
      ul.flex-direction-nav { position: relative; width: 1170px; margin: 0 auto; max-width: 100%; z-index: 20;
        li {
          a {display: block; opacity: 0.8; top: inherit; bottom: 28px; font-size:  1px; color: transparent; text-shadow: none; width: 43px; height: 37px;
            &:before {content: ""; background-image: url(../images/arrow-slider.png); background-repeat: no-repeat; width: 43px; height: 37px;}
            &.flex-prev {left: 20px;
              &:before {background-position: -14px 0;}
            }
            &.flex-next {right: inherit; left: 108px;
              &:before {background-position: -85px 0;}
            }
            &:hover {opacity: 1;}
          }
        }
      }
    }
  }
}


//botanik szczecin

#block-views-front-block-1{ overflow: hidden; background: rgb(238,239,240); /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIxMDAlIiB5Mj0iMCUiPgogICAgPHN0b3Agb2Zmc2V0PSI1MCUiIHN0b3AtY29sb3I9IiNlZWVmZjAiIHN0b3Atb3BhY2l0eT0iMSIvPgogICAgPHN0b3Agb2Zmc2V0PSI1MCUiIHN0b3AtY29sb3I9IiNmYWZiZmMiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(left,  rgb(238,239,240) 50%, rgb(250,251,252) 50%); /* FF3.6-15 */
  background: -webkit-gradient(linear, left top, right top, color-stop(50%,rgb(238,239,240)), color-stop(50%,rgb(250,251,252))); /* Chrome4-9,Safari4-5 */
  background: -webkit-linear-gradient(left,  rgb(238,239,240) 50%,rgb(250,251,252) 50%); /* Chrome10-25,Safari5.1-6 */
  background: -o-linear-gradient(left,  rgb(238,239,240) 50%,rgb(250,251,252) 50%); /* Opera 11.10-11.50 */
  background: -ms-linear-gradient(left,  rgb(238,239,240) 50%,rgb(250,251,252) 50%); /* IE10 preview */
  background: linear-gradient(to right,  rgb(238,239,240) 50%,rgb(250,251,252) 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeff0', endColorstr='#fafbfc',GradientType=1 ); /* IE6-8 */
  .container { position: relative; display: table; background-color: @background;
    &:before {position: absolute; top: 0; left: calc(50% - 585px); content: ""; width: 0; height: 0; border-bottom: 1200px solid @background2; border-right: 1200px solid transparent;}
    .view-header {position: relative; margin-bottom: 70px;
      h1 {color: @purple; font-family: @CR; font-size: 60px; margin: 50px 0 24px;}
      h4 {font-weight: 100; font-size: 15px; font-weight: 100; line-height: 1.5; margin-bottom: 26px;}
    }

    .view-content { position: relative;
      .simple-offer-block { margin-bottom: 40px;
        span {
        //.wrap-image{ width: 140px; height: 140px; margin: 0 auto; overflow: hidden; border-radius: 50%;
          img { margin: 0 auto; border-radius: 50%; //transform: scale(1.0); transition:all 500ms;
            //&:hover{ transform:scale(1.1); transition:all 500ms; }
          }
        }
        }
        h3 { font-family: @RS; text-transform: uppercase; font-size: 18px;
          a {color: @dark-blue;}
        }
      }
    }
  }
//}


//front blog slider

.front-blog-slider { background-image: url(../images/blog-background.jpg); background-repeat: no-repeat; background-size:cover; overflow: hidden; position: relative;
  &:before {position: absolute; content: ""; width: 0; height: 0; top: 0; right: calc(58% - 585px); border-bottom: 1000px solid @dark-purple; border-left: 900px solid transparent;}
  &:after {position: absolute; content: ""; width: calc(58% - 585px); height: 100%; background: @dark-purple; top: 0; display: block; right: 0; visibility: visible;}
  .flexslider { margin-top: 95px;
    .slides { margin-bottom: 37px;
      .img-box {float: right;
        img {border: 1px solid @yellow;}
      }
      .text-box {float: right;
        h3 {font-family: @CR; font-size: 36px; margin-top: 0; line-height: 1.2;
          a {color: @green;}
        }
        p {color: #fff; font-size: 17px;}
        .more-btn {
          a {padding: 0 90px; color: #fff; line-height: 52px; float: left; clar: both; border-radius: 4px; border: 1px solid #fff; margin-top: 33px;}
        }
      }
    }
    ul.flex-direction-nav { position: relative; width: 1170px; margin: 0 auto; max-width: 100%; z-index: 20;
      li {
        a {display: block; opacity: 0.8; top: inherit; bottom: -44px; font-size:  1px; color: transparent; text-shadow: none; width: 43px; height: 37px;
          &:before {content: ""; background-image: url(../images/arrow-slider.png); background-repeat: no-repeat; width: 43px; height: 37px;}
          &.flex-prev {left: 20px;
            &:before {background-position: -14px 0;}
          }
          &.flex-next {right: inherit; left: 108px;
            &:before {background-position: -85px 0;}
          }
          &:hover {opacity: 1;}
        }
      }
    }
  }
  .view-footer {width: 1140px; max-width: 100%; margin: 0 auto; position: relative; z-index: 9;
    .more-btn { float: right; margin: 0 15px 27px 0;
      a {color: #fff; text-transform: uppercase; font-weight: 300; font-size: 24px; padding-right: 30px; line-height: 52px; padding-right: 75px; position: relative;
        &:before {position: absolute; content: ""; background-image: url(../images/arrow-slider.png); background-repeat: no-repeat; background-position: -85px 0; width: 44px; height: 37px; top: 0; right: 0;}
      }
    }
  }
}


//Front guide

.front {
  .guide { position: relative; overflow: hidden;
    &.farmer {
      &:before {position: absolute; content: ""; width: 0; height: 0; top: 0; left: calc(58% - 585px); border-bottom: 700px solid @green; border-right: 700px solid transparent;}
      &:after {position: absolute; content: ""; width: calc(58% - 585px); height: 100%; background: @green; top: 0; display: block; left: 0; visibility: visible;}
      .guide-img-bg {left: 0;}
      //.step-box {
      //  ul {float: right;}
      //}
    }
    &.investor {
      &:before {position: absolute; content: ""; width: 0; height: 0; top: 0; right: calc(58% - 585px); border-bottom: 700px solid @orange; border-left: 700px solid transparent;}
      &:after {position: absolute; content: ""; width: calc(58% - 585px); height: 100%; background: @orange; top: 0; display: block; right: 0; visibility: visible;}
      .guide-img-bg {right: 0;}
    }
    .container { position: relative;
      .guide-img-bg {position: absolute; z-index: 9;}
      .text-box {
        h3 {font-family: @CR; font-size: 32px; margin-top: 85px;
          a {color: @purple;}
        }
      }
      .step-box { margin: 50px 0 70px; z-index: 9;
        ul {list-style-type: none; padding: 0;
          li { width:200px;
            .field-name-field-img { width: 110px; height: 110px; margin: 0 auto; overflow: hidden; border-radius: 50%;
              img {border-radius: 50%; margin: 0 auto; width: 110px; height: inherit; transition:all 500ms;
                &:hover{ transform:scale(1.1); transition:all 500ms;}
              }
            }
            .field-name-field-tips-titile {font-family: @RS; text-transform: uppercase; font-size: 18px; /*width: 130px;*/ margin: 0 auto; text-align: center;
              a {color: @dark-blue;}
              .field-items{ margin-top:20px; }
            }
          }
        }
      }
    }
  }
}


//blog view

.page-blog {
  .view-blog {
    .view-content {
      .views-row {position: relative; margin-bottom: 70px; overflow: hidden;
        .blog-row-text {position: absolute; width: 100%; height: 100%; z-index: 9;
          &:before {position: absolute; content: ""; top: 0; right: 0; width: 0; height: 0; border-bottom: 500px solid @black-purple; border-left: 500px solid transparent; z-index: -1;}
          &:after {position: absolute; content: ""; top: 0; right: 0; width: 0; height: 0; border-top: 270px solid @light-purple; border-left: 270px solid transparent; z-index: -1; opacity: 0.5;}
          .blog-date-block {position: absolute; top: 20px; left: 30px; min-width: @screen-md-min;
            .date {position: relative; color: @light-orange; background: @black-purple; font-family: @RS; font-weight: bold; line-height: 38px; width: 113px; text-align: center;
              &:before {position: absolute; content: ""; top: 0; left: 0; width: 0; height: 0; border-bottom: 17px solid rgba(255, 255, 255, 0); border-right: 89px solid transparent; border-left: 24px solid @purple; border-top: 38px solid @dark-purple;}
              span {position: relative; z-index: 9;}
            }
          }
          p {color: #fff;}
          .blogger-info { text-align: center; padding-top: 30px;
            span {color: #fff; font-family: @RS; text-transform: uppercase; font-weight: bold; font-size: 12px;}
            img {margin: 0 auto; border-radius: 50%; margin-top: 13px;}
            p {color: #fff; font-family: @RS; font-size: 17px; text-transform: uppercase; font-weight: bold; margin-top: 20px;}
          }
          .blog-text-bottom { position: absolute; bottom: 0; width: 100%; background: rgba(0, 0, 0, 0.7);padding: 0 15px 20px;
            &:before {position: absolute; content: ""; bottom: 0; left: 0; width: 0; height: 0; border-bottom: 170px solid @black-purple; border-right: 170px solid transparent;}
            h2 { font-family: @RS; text-transform: uppercase; font-weight: bold; font-size: 23px; margin-top: 24px;
              a {color: @green;}
            }
            h4 {color: @light-orange; text-transform: uppercase; font-family: @RS; font-weight: bold; font-size: 12px; margin: 31px 0 16px;}
            .blog-lead {
              p {font-size: 15px; line-height: 1.5; padding-left: 17px; position: relative;
                &:before {position: absolute; content: ""; top: 2px; left: 0; width: 4px; height: calc(100% - 4px); background: #fff;}
              }
            }
            .next-blog-lead {
              p {text-transform: uppercase; font-family: @RS; font-size: 11px; line-height: 1.85;}
            }
          }
        }
      }
    }
  }
}

//blog page

.node-type-blog {
  .node-header {
    .view-content {
      .views-row {position: relative; overflow: hidden;
        .blog-row-text {position: absolute; width: 100%; height: 100%; z-index: 9;
          &:before {position: absolute; content: ""; top: 0; right: 0; width: 0; height: 0; border-bottom: 500px solid @black-purple; border-left: 500px solid transparent; z-index: -1;}
          &:after {position: absolute; content: ""; top: 0; right: 0; width: 0; height: 0; border-top: 270px solid @light-purple; border-left: 270px solid transparent; z-index: -1; opacity: 0.5;}
          p {color: #fff;}
          .blogger-info { text-align: center; padding-top: 30px;
            span {color: #fff; font-family: @RS; text-transform: uppercase; font-weight: bold; font-size: 12px;}
            img {margin: 0 auto; border-radius: 50%; margin-top: 13px;}
            p {color: #fff; font-family: @RS; font-size: 17px; text-transform: uppercase; font-weight: bold; margin-top: 20px;}
          }
        }
        .img-header {position: relative;
          &:before {position: absolute; content: ""; bottom: 0; left: 0; width: 0; height: 0; border-bottom: 170px solid @black-purple; border-right: 170px solid transparent;}
        }
      }
    }
  }
  .main-container.container {width: 1030px; max-width:100%; background: #fff; position: relative; z-index: 10; margin: -73px auto 48px;
    h1.page-header {margin-top: 28px; margin: 30px 0 15px;}
    .field-name-date {position: relative; color: @light-orange; background: @black-purple; font-family: @RS; font-weight: bold; line-height: 38px; width: 113px; text-align: center; margin-bottom: 40px;
      &:before {position: absolute; content: ""; top: 0; left: 0; width: 0; height: 0; border-bottom: 17px solid rgba(255, 255, 255, 0); border-right: 89px solid transparent; border-left: 24px solid @purple; border-top: 38px solid @dark-purple;}
      .view-content {position: relative; z-index: 9;}
    }
    .field-name-field-lead {font-weight: bold; font-size: 16px; line-height: 1.5; margin-bottom: 28px;}
    .field-name-body {
      p {font-size: 16px;}
    }
  }
}

//other blog in node

.other-blog-view { margin-bottom: 60px;
  .field-content {position: relative;
    h2 {position: absolute; width: 100%; bottom: 0; background: rgba(0, 0, 0, 0.7); margin: 0; text-transform: uppercase; font-family: @RS; font-weight: bold; font-size: 17px; padding: 15px 15px 20px;
      a {color: #fff;}
    }
  }
}

//top page
.fullwidth-region {
  .page-header-bg {background-image: url(../images/top-node-bg); background-color: @black-purple; height: 158px; background-position: top right; background-repeat: no-repeat; position: relative; overflow: hidden;
    &:before {position: absolute; content: ""; top: 0; right: 0; width: 368px; height: 0; border-bottom: 158px solid @black-purple; border-left: 158px solid transparent;}
    .top-node-bg {width: 100%; height: 100%;
      &:before {position: absolute; content: ""; top: 0; left: 0; width: 50%; height: 0; border-top: 158px solid @dark-purple; border-right: 158px solid transparent; opacity: 0.7;}
      &:after {position: absolute; content: ""; top: 0; left: 0; width: 33%; height: 0; border-top: 158px solid @black-purple; border-right: 158px solid transparent;}
      .top-img {background-image: url(../images/slider-flower.png); background-repeat: no-repeat; background-position: left center; width: 400px; height: 158px; position: relative; z-index: 9;}
    }
  }
}