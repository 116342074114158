
body.not-front  { padding-top: 113px !important; }


//General Web-Form styles
.webform-client-form {
  .form-group { margin-bottom: 24px;
    input { height: 40px; border: none;  box-shadow: none; }

    ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: @light-purple;
      text-transform: uppercase;
    }
    ::-moz-placeholder { /* Firefox 19+ */
      color: @light-purple;
      text-transform: uppercase;
    }
    :-ms-input-placeholder { /* IE 10+ */
      color: @light-purple;
      text-transform: uppercase;
    }
    :-moz-placeholder { /* Firefox 18- */
      color: @light-purple;
      text-transform: uppercase;
    }

    textarea {  height: 100px;  border: none;  box-shadow: none;  border-radius: 4px 4px; }
    .grippie { display: none; }
  }

  .webform-submit { float: right; clear: right; background-color: @green; color: #fff; text-transform: uppercase; }

}

.wrap-image{ width: 140px; height: 140px; margin: 0 auto; overflow: hidden; border-radius: 50%;
  img { margin: 0 auto; border-radius: 50%; transform: scale(1.0); transition:all 500ms;
    &:hover{ transform:scale(1.1); transition:all 500ms; }
  }
}

//#block-views-front-block-1{
//    .view-content {
//      .simple-offer-block {
//        .wrap-image { width: 140px; height: 140px; margin: 0 auto; overflow: hidden; border-radius: 50%;
//          img { border-radius: 50%;transform: scale(1.0); transition:all 500ms;
//            &:hover{ transform:scale(1.1); transition:all 500ms; }
//          }
//        }
//        h3 { font-family: @RS; text-transform: uppercase; font-size: 18px;
//          a {color: @dark-blue;}
//        }
//      }
//    }
//}


//Front step box add icon
.front {
  .farmer {
    .guide-img-bg img { transform:scaleX(-1); }
  }

  //.guide {
  //  .step-box ul {
  //    //li { margin-right: 50px; margin-left:50px; position: relative;
  //    //  &:last-child { margin-right: 0; }
  //    //  &:first-child { margin-left:0; }
  //    //  &:before {  content: "";  position: absolute;  top: 31px;  left: -50px;  background-image: url(../images/guide-icon.png);  width: 30px;  height: 50px;  }
  //    //  &.first:before { content: ""; display: none; }
  //    }
  //  }
  //}
}

//Oferta subbpage - oferta
.node-type-offer {
  h1 { margin-top: 0; padding-bottom: 20px; }
  .main-container {
    .node-offer { margin-top: 35px;
      .left{
        .field-name-body { margin-bottom: 30px;
          p { font-size:16px; line-height: 24px; margin-top: 35px; }
        }
        .field-name-wskazowki {
          #block-views-offer-tips-block {
            h3 { font-family: @RS; color: @purple; }
            ul { list-style: none;
              li{ margin-top: 30px; padding: 0 32px;  position: relative;
                &:before { position: absolute; content: " "; background-image: url(../images/tips-icon.png); display: block;  top: 5px;  left: -33px;  width: 41px;  height: 29px; }
              }
            }
          }
        } // /field-name-wskazowki
        .field-name-detail {
          .panel-group {
            .panel-default, .panel { border:none; background-color: transparent; box-shadow: none;
              .panel-heading {border: none; padding: 35px 0; background-color: transparent;
                .panel-title {
                  a { text-decoration: underline; }
                }
              }
            }
          }

        }// / field-name-detail
      } // /group-left


      .right {
        .view-form-offer {
          .view-content {
            .views-field-webform-form-body { padding: 30px; background: @light-purple; overflow: hidden; margin-top:12px;
              h3 { text-transform: uppercase; text-align: center; color: @green; font-family: @RS; font-size: 34px; padding-bottom: 35px;  }
              input{ background-color:#fff; }
              .form-actions {
                .webform-submit { border: 5px solid #897eb8; padding: 10px 45px; }
              }
            }
          }
          .view-footer { margin-top: 35px;
            p { color:#4b4d54 }
            h5 { color: #7c6fb0; font-size: 30px; font-family:@RS; font-weight: bold;
              span { color: #ababab; }
            }
          }
        }
      }// </group-right
    }
  }
}

//Oferta subbpage - other offer
.node-type-offer {
  .bottom-region  { position: relative; overflow: hidden; margin-top:0; padding: 0; width:100%;font-family:@CR; color: @light-purple; font-size: 18px; background-image: url(../images/offer-footer-bg.png); background-repeat: no-repeat; background-size:cover;
       &:before{ position: absolute;content: ""; width: 0;height: 0;top: 0px; right: calc(58% - 585px);border-bottom: 1000px solid #382362; border-left: 1000px solid transparent; }
       &:after { position: absolute; content: ""; width: calc(58% - 585px); height: 100%; background: #382362; top: 0; display: block; right: 0;  visibility: visible; }
       .view-form-offer { margin: 0 auto; padding:35px; overflow:hidden;

         .other-offer {
            //&:nth-child(5n) { clear:left; }
           .field-content {
             a { display:inline-block; margin-bottom:30px; margin-top:30px;  position: relative; color:@light-purple; padding-left: 30px; }
             a:before { position:absolute; content: ""; background-image: url(../images/offer-icon.png);  background-repeat: no-repeat;  width: 13px;  height: 24px;  top: 0;  left: -20px;  }
           }
         }
       }
  }

  .block-views-form-offer-block-2 { width:1170px; margin:0 auto;  }
}

// Contact subbpage -form
.node-type-webform {
  .main-container {
    .node-webform  { margin-bottom:40px;
      .left { font-size:16px; padding-right: 20px; padding-left: 0;
        p {line-height: 24px; margin-bottom:0;color: #56585e;
          &:first-child{ color: #4b4d54; }
          a { color:#56585e;}
        }
        h3 { font-family: @RS; color: @purple; margin-top:35px; }
      } //</group-left>

      .right {
        .webform-client-form-22 {
          input, textarea {  background-color:#eeebfc;  }

          .webform-submit { padding: 10px 67px; margin-top:15px; border:none;}
        }
      }// </group-right>
    }
  } // </main-container>
}

// Contact subbpage -block with contacts & map
#block-views-contact-map-block-1 {
  .view-contact-map{
    .view-header { background: @light-purple; position: relative; overflow: hidden; padding:53px 0;
      .container { padding:0;}
        .contact-single { color: #e5e2ef; font-size: 16px; z-index:10; margin-bottom:50px;
          a { color:#e5e2ef; }
          .views-field-title{
            h4 { font-family: @RS; color: @yellow; position:relative; padding-bottom: 7px; margin-bottom:22px; text-transform: uppercase;
              &:after{ position: absolute; content: "";  height: 2px;  width: 70px;  background-color: #ffe000;  left: 0; bottom: 0; }
            }
          } // </.views-field-title

          .views-label{ float:left; }
          .views-field-field-code-city , .views-field-field-phone-1{ margin-bottom:15px;}
          .views-field-field-location {
            a { padding:7px 13px; font-size:14px; border: 2px solid #c1c1c1; border-radius: 3px; background: transparent; text-transform: lowercase; transition:all 500ms;
              &:hover, &:focus, &:active { text-decoration: none; background-color:@green; border-color:@green; color: #fff; transition:all 500ms; outline: none;}

            }
          }
        } // </.contact-single
    } //</.view-header
    .view-content { margin-top:0;}
  }
}

//Footer
.footer-wrap { background-image: url(../images/footer-img.png);  background-repeat: no-repeat;  background-position: bottom left;  background-color: @black-purple;
  footer { margin-top:0; border:none; padding-bottom: 5px;
    .footer-block{ color:@light-purple; font-size:13px; padding: 0;min-height: 0 !important;
      h5 { font-size:15px; font-family: @CR; color:#fff; text-transform: uppercase; }
      a { color:@light-purple; }
      &:nth-child(2):before, &:nth-child(3):before { position: absolute;  width: 1px;  height: 75px;  background: #443269;  top: 11px;  left: -30px;  display: block; }
      &:last-child { text-align: center;
        .social-icons { padding-top: 10px;
          img { margin: 10px 5px; }
        }
        .created { padding-bottom: 10px; }
      }
    }
  } //</footer
}


//additional style to navbar
#navbar {padding-bottom:25px; border:none; margin-bottom:0;
  #block-block-2 { cursor: pointer; }
}
.page-header-bg {
  border-top: 2px solid #f39200 !important;
  border-bottom: 2px solid #f39200 !important;
  margin-bottom:50px;
}


//Respo
@media (max-width: @screen-xs-max) {

   .not-front h1 { text-align:center;}

  //subpage oferta
   .node-type-webform {
       .node-webform  {
         .left { text-align:center; margin-bottom:40px;
         } //</group-left>
         .right {
           .webform-client-form-22 {
             .float-input { width: 100%; float: none;
             }
             .form-actions { text-align: center;
               .webform-submit { float: none;}
             }
           }
         }// </group-right>
       }
   }

   //subpage blog
   .page-blog {
     .view-blog {
       .blog-row-text {
         .blog-date-block { position: absolute; top: 0; left: 0; width:100%;
           .data { width:100%; }
         }
       }
       p {color: #fff;}
       .blogger-info { display:none;}
       .blog-text-bottom { background:transparent;
         h2 { text-align:center; }
         h4 {color: @light-orange; text-transform: uppercase; font-family: @RS; font-weight: bold; font-size: 12px; margin: 31px 0 16px;}
         .blog-lead {
           p {display: none;}
         }
         .next-blog-lead { display:none; }
       }
     }
   }

   //blog page
  .node-type-blog {
    .view-id-blog {
     .img-header img { height:350px; }
   }
    .other-blog-view img {
      width:100%;
    }
  }

  //Oferta subbpage - other offer
  .node-type-offer {
    .view-form-offer { text-align: center; }
  }

  //step
  .front {
    .farmer {
      .guide-img-bg img { display:none; }
      .step-box {
        ul { float:none;}
      }
    }

    .investor {
      .guide-img-bg img { display:none; }
    }

    .guide {
      .container {
        .text-box { text-align: center; }
        .step-box{
          ul { width:100%;  margin:0 auto;
          li { position: relative; margin:100px auto;
            &:before {top:-83px; left: 83px;  }
          }

          }
        }
      }
    }
  }

  .top-slider {
    .view-front {
      .flexslider {
        .slides li {
          .views-field {
            .container {
              img{ height: 55%;}
            }
          }
        }
      }
    }
  }



 }  //@meadia -xs-max

@media (max-width: @screen-sm-max) {
  //.page-blog{
  //  .blog-text-bottom {
  //    .next-blog-lead { display:none; }
  //  }
  //}

  //stepbox
  .front {
    .guide {
      .guide-img-bg {bottom: 0;}
      .step-box ul { width:200px;
        li { width:100%;  position:relative; margin-bottom:100px;
          &:last-child { margin-bottom:0; }
          &:first-child {  }
          &:before {content: ""; position: absolute; top:-83px; left: 83px; background-image: url(../images/guide-icon.png); width: 30px; height: 50px; transform:rotate(90deg); }
          &.first:before { content: ""; display: none; }
        }
      } //</step-box ul
    }
  } // </front


} //@screen-sm-max
@media (min-width: @screen-sm-min) {
  .front {
    .farmer {
      .step-box {
        ul {
          float: right;
        }
      }
    }
  }
}

@media (max-width: @screen-md-max) {
  .front {
    .guide { position: relative; overflow: hidden;
      &.farmer {
        &:before { position: absolute; content: ""; width: 0; height: 0; top: 0; left: calc(58% - 585px); border-bottom: 1200px solid @green; border-right: 1200px solid transparent; }
        .guide-img-bg { left: 0; }
        .step-box {
          ul { float: right; }
        }
      } //</farmer

      &.investor {
        &:before { position: absolute;  content: "";  width: 0;  height: 0;  top: 0;  right: calc(58% - 585px);  border-bottom: 1200px solid @orange; border-left: 1200px solid transparent; }
        .guide-img-bg { right: 0;}
        }
      .container { position: relative;
        .step-box {
          ul {
            li { margin-top: 35px; }
          }
        }
      } //</container
    } //</guide
  } //</front

  .footer {
    .footer-block { text-align: center; margin-bottom: 20px; }
  } // </footer

} // @screen-md-max)

@media (min-width: @screen-md-min) {
    //Contact subpage - form
    .node-type-webform {
      .node-webform {
        .right {
          .webform-client-form-22 {
            .float-input { width: 50%; float: left; }
            .webform-component-email { padding-right: 15px; }
            .webform-component-number { padding-left: 15px; }
          }
        } // </right
      }
    } // </node-type-webform

    // Contact subbpage -block with contacts & map
    #block-views-contact-map-block-1 {
      .view-contact-map {
        .view-header {
          .container {padding: 0;}
          &:before { position: absolute; content: ""; width: 0; height: 0; top: 0; right: calc(56% - 583px); border-bottom: 1000px solid @purple; border-left: 700px solid transparent; }
          &:after { position: absolute; content: ""; width: calc(56% - 583px); height: 100%; background: @purple; top: 0; display: block; right: 0; visibility: visible;  }
        }//</.view-header
      }
    } //</#block-views-contact-map-block-1

    //Blog subpage
    .page-blog {
      .blog-date-block { top: 20px; left: 30px;
        .data { width: 113px; }
      }
      .block-text-bottom {
        background: rgba(0, 0, 0, 0.7);
      }
    }

    //stepbox
    .front {
      .guide {
        .step-box ul {
          li { margin-right: 50px; margin-left: 50px; position: relative; float: left; width:200px;
            &:last-child { margin-right: 0; }
            &:first-child { margin-left: 0; }
            &:before {content: ""; position: absolute; top: 31px; left: -50px; background-image: url(../images/guide-icon.png); width: 30px; height: 50px; }
            &.first:before { content: ""; display: none; }
          }
        } //</step-box ul
      }
    } // </front

    .footer-wrap {
      background-position: top left;
      .created { margin-top: 40px; }
    }
  } // </@screen-md-min

//Sicky class for menu

#navbar { transition: all 500ms;
  .logo {
    img { transition: all 1000ms;}
  }
}
.sticky-menu { padding-bottom: 10px !important;  box-shadow: 0 5px 5px #888888; transition: all 500ms;
  .logo { transition: all 1000ms;
    img { height:50px; transition: all 1000ms; }
  }

  #block-block-2 span {
    margin-top: 10px !important;
  }
  }
